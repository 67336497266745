import { useState } from "react";
import { overrideTailwindClasses } from "tailwind-override";

export const IconButton = ({
  className,
  onClick,
  iconColor,
  iconHoverColor,
  iconSize,
  Icon,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <div
      className={overrideTailwindClasses(
        `w-10 h-9 flex justify-center items-center cursor-pointer ${className}`
      )}
      onClick={onClick}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <Icon
        size={iconSize || 24}
        color={isHovering ? iconHoverColor : iconColor}
      />
    </div>
  );
};
