import { useCallback, useEffect, useState } from "react";
import { overrideTailwindClasses } from "tailwind-override";
import { GetNFTTotalSold, GetNFTTotalSupply } from "../data/ContractInterface";

export const TotalSupplyDisplay = () => {
  const [totalSold, setTotalSold] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);

  const refresh = useCallback(async () => {
    const sold = await GetNFTTotalSold(window.ethereum);
    const supply = await GetNFTTotalSupply(window.ethereum);

    setTotalSold(sold);
    setTotalSupply(supply);
  }, [setTotalSold, setTotalSupply]);

  useEffect(() => {
    refresh();
    setInterval(refresh, 10000);
  }, [refresh]);
  
  return (
    <div
      className={overrideTailwindClasses(
        `px-2 py-2`
      )}
    >
      <span className="font-bold text-2xl">{totalSold}</span> out of <span className="font-bold text-2xl">{totalSupply}</span> NFTs have been minted!
    </div>
  );
};
