import { ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";
import SmartContract from "./VikingArtCollective.json";

//"0xDC4ddC5a01c1df06385974Db488c7CADb733308f"
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

const IsOnWhiteList = async (ethereum, address) => {
  const connectedContract = await GetConnectedContract(ethereum);
  return connectedContract.isMemberOnWhitelist(address);
};

const HasWhiteListMintingStarted = async (ethereum) => {
  const connectedContract = await GetConnectedContract(ethereum);
  return connectedContract.whitelistMinting();
};

const IsMintingPublic = async (ethereum) => {
  const connectedContract = await GetConnectedContract(ethereum);
  return connectedContract.publicMinting();
};

const GetConnectedContract = async (ethereum) => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  await provider.send('eth_requestAccounts', []);
  const signer = provider.getSigner();
  return new ethers.Contract(CONTRACT_ADDRESS, SmartContract.abi, signer);
};

const PublicMint = async (ethereum, address) => {
  const connectedContract = await GetConnectedContract(ethereum);

  console.log("Going to pop wallet now to pay gas...");
  let price = await connectedContract.price();
  let nftTxn = await connectedContract.mintPublic(address, {
    value: price,
  });

  console.log("Mining...please wait.");
  return nftTxn.wait();
};

const WhiteListMint = async (ethereum, address) => {
  const connectedContract = await GetConnectedContract(ethereum);

  console.log("Going to pop wallet now to pay gas...");
  let price = await connectedContract.price();
  let nftTxn = await connectedContract.mint(address, {
    value: price,
  });

  console.log("Mining...please wait.");
  return nftTxn.wait();
};

const GetNFTCount = async (ethereum, address) => {
  const connectedContract = await GetConnectedContract(ethereum);
  let balanceTxn = await connectedContract.balanceOf(address);
  return balanceTxn.toNumber();
};

const GetNFTTotalSupply = async (ethereum) => {
  const connectedContract = await GetConnectedContract(ethereum);
  let sellerConfig = await connectedContract.sellerConfig();
  
  return sellerConfig.totalInventory.toNumber();
};

const GetNFTTotalSold = async (ethereum) => {
  const connectedContract = await GetConnectedContract(ethereum);
  let totalSold = await connectedContract.totalSold();
  return totalSold.toNumber();
};

const GetPriceInETH = async (ethereum) => {
  const connectedContract = await GetConnectedContract(ethereum);

  console.log("Going to pop wallet now to pay gas...");
  let price = await connectedContract.price();
  return formatEther(price);
};

export {
  IsOnWhiteList,
  HasWhiteListMintingStarted,
  IsMintingPublic,
  PublicMint,
  GetNFTCount,
  GetNFTTotalSupply,
  GetNFTTotalSold,
  WhiteListMint,
  GetPriceInETH
};
