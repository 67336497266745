import { useEffect, useRef, useState } from "react";
import { overrideTailwindClasses } from "tailwind-override";

export const MessageDisplay = ({ message, level }) => {
  const cssClassNames = [];

  const [display, setDisplay] = useState(true);
  const timer = useRef(null);

  switch (level) {
    case 'info':
      cssClassNames.push('bg-infoLight');
      cssClassNames.push('text-infoDark');
      break;
    case 'warning':
      cssClassNames.push('bg-warningLight');
      cssClassNames.push('text-warningDark');
      break;
    case 'success':
      cssClassNames.push('bg-successLight');
      cssClassNames.push('text-successDark');
      break;
    case 'error':
      cssClassNames.push('bg-errorLight');
      cssClassNames.push('text-errorDark');
      break;
    default:
      cssClassNames.push('bg-infoLight');
      cssClassNames.push('text-infoDark');
      break;
  }

  // Removing timer feature
  // useEffect(() => {
  //   setDisplay(true);
  //   clearTimeout(timer.current);
  //   timer.current = setTimeout(() => setDisplay(false), 10000);
  // }, [message])

  if(!display) {
    cssClassNames.push('animate-fade-out-down');
  } else {
    cssClassNames.push('animate-fade-in-up');
  }
  
  return (
    <div
      className={overrideTailwindClasses(
        `px-2 py-1 flex flex-col text-sm fill- ${cssClassNames.join(' ')}`
      )}
    >
      { message }
    </div>
  );
};
