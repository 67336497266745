import { useEffect, useState, useCallback } from "react";
import {
  HasWhiteListMintingStarted,
  IsOnWhiteList,
  IsMintingPublic,
  PublicMint,
  GetNFTCount,
  GetNFTTotalSold,
  GetNFTTotalSupply,
  WhiteListMint,
  GetPriceInETH
} from "../data/ContractInterface";
import { Button } from "./Button";
import { MessageDisplay } from "./MessageDisplay";
import { TotalSupplyDisplay } from "./TotalSupplyDisplay";



export const MintWidget = ({ address }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [message, setMessage] = useState();
  const [isMintingPublic, setIsMintingPublic] = useState(false);
  const [price, setPrice] = useState(null);

  const checkSoldOut = async () => {
    const sold = await GetNFTTotalSold(window.ethereum);
    const supply = await GetNFTTotalSupply(window.ethereum);

    return sold >= supply;
  };

  useEffect(() => {
    setIsLoading(true);
    setMessage({ message: 'Checking NFT status...', level: 'info' });

    const checkPermissions = async () => {
      try {
        
        console.log("Checking permission " + address);
        
        const isSoldOut = await checkSoldOut();

        console.log("isSoldOut " + isSoldOut);
        
        if (isSoldOut) {
          setIsDisabled(true);
          setIsLoading(false);
          setMessage({ message: 'Sold out! Visit opensea.io for secondary market purchases.', level: 'info' })
          return;
        }
        
        const isPublic = await IsMintingPublic(window.ethereum);
        setIsMintingPublic(isPublic);

        const priceInEth = await GetPriceInETH(window.ethereum);
        setPrice(priceInEth);

        console.log("isPublic " + isPublic);

        if (!isPublic) {
          const isOnWhiteList = await IsOnWhiteList(window.ethereum, address);
          console.log("isOnWhiteList " + isOnWhiteList);

          if (isOnWhiteList) {
            const nftCount = await GetNFTCount(window.ethereum, address);

            if (nftCount > 0) {
              setIsDisabled(true);
              setMessage({ message: 'Already minted, please wait for public minting to mint more', level: 'warning' });
              return;
            }

            const hasWhiteListMintingStarted = await HasWhiteListMintingStarted(window.ethereum);
            console.log("hasWhiteListMintingStarted " + hasWhiteListMintingStarted);
            
            if (hasWhiteListMintingStarted) {
              setIsDisabled(false);
              setMessage({ message: 'Ready to Mint!', level: 'success' });
            } else {
              setMessage({ message: 'You\'re on the whitelist but minting has not yet begun.', level: 'warning' });
            }
          } else {
            setMessage({ message: 'Check back when minting is open to the public', level: 'warning' });
          }
        } else {
          setIsDisabled(false);
          setMessage({ message: 'Ready to Mint!', level: 'success' });
        }

        setIsLoading(false);
      } catch (e) {
        setMessage({ message: 'An error has occurred while checking mint status', level: 'error'})
        setIsLoading(false);
        console.log(e);
      }
    };
    checkPermissions();
  }, [address]);

  const askContractToMintNft = useCallback(async () => {
    try {
      const { ethereum } = window;
      setIsLoading(true);
      setIsDisabled(true);
  
      if (ethereum) {
        setMessage({ message: 'Minting your NFT...', level: 'info' })
        if (isMintingPublic) {
          await PublicMint(ethereum, address);
          setIsLoading(false);
          setIsDisabled(false);
        } else {
          const nftCount = await GetNFTCount(window.ethereum, address);

          if (nftCount > 0) {
            setIsLoading(false);
            setIsDisabled(true);
            setMessage({ message: 'Already minted, please wait for public minting to mint more', level: 'warning' });
            return;
          }

          await WhiteListMint(ethereum, address);
        }
        setMessage({ message: 'Your NFT is successfully minted!', level: 'success' })
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      setMessage({ message: 'There was an error minting your NFT', level: 'error' })
      console.log(error)
      setIsLoading(false);
      setIsDisabled(false);
    }
    
  }, [address, isMintingPublic, setIsLoading, setIsDisabled, setMessage]);

  let ethPriceText = '';
  
  if (isMintingPublic) {
    ethPriceText = 'Price 1 ETH';
  } else {
    ethPriceText = `Allowlist mint price ${price} ETH, public mint price 1 ETH`;
  }

  return (
    <div className="w-full flex flex-col items-center px-3">
      <div>
        <TotalSupplyDisplay />
      </div>
      <Button
        className="sm:max-w-sm w-full mb-1"
        isLoading={isLoading}
        isDisabled={isDisabled}
        text="Mint an NFT"
        onClick={() => askContractToMintNft()}
      />
      <div className="mb-3">
        { ethPriceText }
      </div>
      <div className="sm:max-w-sm w-full">
        { message && <MessageDisplay message={message.message} level={message.level} /> }
      </div>
      {/* <div className="sm:max-w-sm w-full mx-5">
        <div>Minted NFTS: {nftCount}</div>
      </div> */}
    </div>
  );
};
