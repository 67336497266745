import "./App.css";

import { useAccount } from "wagmi";

import {
  useConnectModal,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import "@rainbow-me/rainbowkit/styles.css";
import { Button } from "./components/Button";
import { MintWidget } from "./components/MintWidget";
import { Card } from "./components/Card";

import logoSmall from "./media/images/logoSmall.svg";
import logo from "./media/images/logo.png";

import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { GiVikingLonghouse, GiVikingHead, GiVikingShield, GiVikingHelmet } from "react-icons/gi"
import { IconButton } from "./components/IconButton";

const { chains, provider } = configureChains(
  [
    chain[process.env.REACT_APP_NETWORK]
  ],
  [alchemyProvider({ alchemyId: process.env.ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "VikingArt",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const Page = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { openConnectModal } = useConnectModal();

  return (
    <div className="flex flex-col font-glacial">
      <div className="bg-secondary1 flex flex-row justify-between items-center px-8">
        <img src={logoSmall} alt="VikingArtLogo" />
        <div className="flex flex-row">
          <IconButton
            Icon={BsTwitter}
            iconColor="#9CB3B3"
            iconHoverColor="#093837"
            iconSize={24}
            onClick={() => window.open("http://twitter.com/vikingart_xyz", "_blank")}
          />
        </div>
      </div>
      <div className="flex justify-center my-10">
        <img src={logo} alt="vikingartlogo" />
      </div>
      <div className="flex justify-center mb-16">
        {!address && (
          <Button
            className="sm:max-w-sm w-full mx-5"
            onClick={openConnectModal}
            isLoading={isConnecting}
            text="Connect Wallet"
          />
        )}
        {address && <MintWidget address={address} />}
      </div>
      <div className="flex flex-wrap flex-row justify-center">
        <Card
          TitleIcon={GiVikingLonghouse}
          title="Private Membership Group"
          className="w-full mb-2 sm:w-72 sm:mx-6 sm:mb-10"
        >
          Private token gates resources, including a private Discord channel.
          Three year membership benefits.
        </Card>
        <Card
          TitleIcon={GiVikingHead}
          title="Olaf Oden Viking Art"
          className="w-full mb-2 sm:w-72 sm:mx-6 sm:mb-10"
        >
          Free mints (gas only) of Viking Art by Olaf Oden during the membership
          period.
        </Card>
        <Card
          TitleIcon={GiVikingHelmet}
          title="Spin-off projects"
          className="w-full mb-2 sm:w-72 sm:mx-6 sm:mb-10"
        >
          Early privileged access to spin-off projects and collaborations.
        </Card>
        <Card
          TitleIcon={GiVikingShield}
          title="Other Benefits"
          className="w-full mb-2 sm:w-72 sm:mx-6 sm:mb-10"
        >
          Merchandise, IRL events, and other benefits that will be announced
          once ready.
        </Card>
      </div>
      <div className="bg-secondary1 flex justify-center items-center font-glacial text-primary1 h-16">
        <div className="w-72 flex justify-evenly">
          <a href="https://metadata.vikingart.com/terms.html">Terms of Sale</a>
          <a href="https://vikingart.com">More Information</a>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <Page />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
