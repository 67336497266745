import { overrideTailwindClasses } from "tailwind-override";

export const Card = ({ title, className, TitleIcon, children }) => {
  
  return (
    <div
      className={overrideTailwindClasses(
        `bg-primary4 px-8 py-10 flex flex-col ${className}`
      )}
    >
      {
        title && (
          <div className="flex flex-col">
            <div className="flex flex-row">
              <TitleIcon size={32}  />
              <div className="ml-3 mb-3 font-terek font-bold text-4xl uppercase">{title}</div>
            </div>
            <div className="bg-white w-full h-[2px] mb-3" />
          </div>
        )
      }
      {children}
    </div>
  );
};
